import React from 'react';
import PropTypes from 'prop-types';
import ScoreCircle from '../ScoreCircle';

import styles from './ScoreSummary.module.scss';

class ScoreSummary extends React.Component {

  renderSubjects () {
    const { 
      testID,
      mathScore,
      elaScore,
      scienceScore,
      readingScore,
      englishScore } = this.props;
      
    const range = (testID === 'SAT') ? 800: 36;
    if (testID === 'SAT') {
      return (
        <React.Fragment>
          <ScoreCircle
            score={mathScore}
            range={range}
            chartTitle={'Math'}
            scoreColor={'#fac58e'}></ScoreCircle>
          <ScoreCircle
            score={elaScore}
            range={range}
            chartTitle={'ELA'}
            scoreColor={'#5ba0d0'}></ScoreCircle>
        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>
          <ScoreCircle
            score={englishScore}
            range={range}
            chartTitle={'English'}
            scoreColor={'#5ba0d0'}></ScoreCircle>
          <ScoreCircle
            score={mathScore}
            range={range}
            chartTitle={'Math'}
            scoreColor={'#fac58e'}></ScoreCircle>
           <ScoreCircle
            score={readingScore}
            range={range}
            chartTitle={'Reading'}></ScoreCircle>
          <ScoreCircle
            score={scienceScore}
            range={range}
            chartTitle={'Science'}></ScoreCircle>
        </React.Fragment>
      );
    }
  }

  render () {
    const { testID, compositeScore } = this.props;
    const range = (testID === 'SAT') ? 1600: 36;
    
    return (
      <div className={styles.scoreSummary}>
        <div className={styles.composite}>
          <ScoreCircle
            score={compositeScore}
            range={range}
            chartTitle={'Composite'}
            scoreColor={'#00a2a7'}></ScoreCircle>
        </div>
        <div className={styles.sections}>
          {this.renderSubjects()}
        </div>
      </div>
    );
  }
}

ScoreSummary.propTypes = {
  testID: PropTypes.string.isRequired,
  compositeScore: PropTypes.number,
  mathScore: PropTypes.number,
  elaScore: PropTypes.number,
  scienceScore: PropTypes.number,
  readingScore: PropTypes.number,
  englishScore: PropTypes.number,
};

export default ScoreSummary;