import React from 'react';
import PropTypes from 'prop-types';

import Question from '../Question';
import GridinQuestion from '../GridinQuestion';

import styles from './MathTest.module.scss';

class MathTest extends React.Component {

  chooseAnswer = (answerID) => {
    this.props.chooseAnswer(answerID);
  }

  render() {
    const { question, questionNumber } = this.props;

    return (
      <div className={styles.mathTest}>
        <div className={styles.questionContainer}>
          { question.isGridin ? 
            <GridinQuestion
              questionNumber={questionNumber}
              questionBody={question.body}
              answerOptions={question.answers}
              chooseAnswer={this.chooseAnswer}/>
            :
            <Question 
              questionNumber={questionNumber}
              questionBody={question.body}
              answerOptions={question.answers}
              chooseAnswer={this.chooseAnswer}/>
          }
        </div>   
      </div>
    );
  }
}

MathTest.propTypes = {
  question: PropTypes.object.isRequired,
  questionNumber: PropTypes.number.isRequired,
  chooseAnswer: PropTypes.func.isRequired
};

export default MathTest;