import images from '../assets/Images';

const math = '#3B3561';
const ela = '#134f8d';
const science = '#963484';
const reading = '#00a2a7';

export default 
  { 
    SAT: {
        testID: 'SAT',
        v3TestIdentifier: 'AdaptiveTestSAT',
        testTitle: 'Testive Express Test SAT',
        totalTime: '1h 30m',
        totalTimeExtended: '2h 15m',
        sections: [
          { 
            subject: 'math', 
            displayName: 'Math', 
            v3TaxonomyKey: 'AdaptiveTestSATMath',
            image: images.math,
            color: math,
            description: ['25 Math Calculator Questions', '10 no math calculator questions', '35 Questions 49 Minutes'],
            time: 2940, //49 minutes
            questionCount: 35,
            //Math: 25 questions 1:26 per = 2150s = 36
            //Math No Calc: 10 questions 1:15 per = 750s = 12.5
          },
          { 
            subject: 'ela', 
            displayName: 'Evidence-Based Reading and Writing',
            v3TaxonomyKey: 'AdaptiveTestSATEla',
            image: images.ela,
            color: ela,
            description: ['2 Reading Passages', '2 Writing Passages','40 Queestions 41 Minutes'],
            questionCount: 40,
            time: 2460, //41 minutes
          }
        ]
      },
    ACT : {
      testID: 'ACT',
      v3TestIdentifier: 'AdaptiveTestACT',
      testTitle: 'Testive Express Test ACT',
      totalTime: '1h 23m + 2m break',
      totalTimeExtended: '2h 30m + 10m break',
      sections: [
        { 
          subject: 'english', 
          displayName: 'Evidence-Based Reading and Writing',
          v3TaxonomyKey: 'AdaptiveTestSATEla',
          image: images.ela,
          color: ela,
          description: ['2 Reading Passages', '2 Writing Passages','40 Queestions 41 Minutes'],
          questionCount: 40,
        },
        { 
          subject: 'math', 
          displayName: 'Math', 
          v3TaxonomyKey: 'AdaptiveTestSATMath',
          image: images.math,
          color: math,
          description: ['25 Math Calculator Questions', '10 no math calculator questions', '35 Questions 49 Minutes'],
          time: 2940, //49 minutes
          questionCount: 35,
          //Math: 25 questions 1:26 per = 2150s = 36
          //Math No Calc: 10 questions 1:15 per = 750s = 12.5
        },
        { 
          subject: 'reading', 
          displayName: 'Reading', 
          v3TaxonomyKey: 'AdaptiveTestACTReading',
          image: images.math,
          color: reading,
          description: ['2 Passages', '20 Questions 49 Minutes'],
          time: 2940, //49 minutes
          questionCount: 20,
          //Math: 25 questions 1:26 per = 2150s = 36
          //Math No Calc: 10 questions 1:15 per = 750s = 12.5
        },
        { subject: 'science',
          displayName: 'Science', 
          v3TaxonomyKey: 'AdaptiveTestACTScience',
          image: images.science,
          color: science,
          description: ['2 Passages', '20 Questions 49 Minutes'],
          time: 2940, //49 minutes
          questionCount: 20,
      },
      ]
    }
  }
  
    