import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';

import styles from './Percentile.module.scss';

class Percentile extends React.Component {

  rend
  render() {
    const { percent, chartTitle, color } = this.props;

    const colorCompleted = color ? color : styles.completed;

    const metricValue = percent ? Math.round(percent * 100) : '?'
    const handleNoValue = percent ? percent: 0;
    const data = [
      {name: 'percent', value: handleNoValue, fill: colorCompleted},
      {name: 'empty', value: 1-handleNoValue, fill: colorCompleted, fillOpacity:.25},
    ];
    const dataOutline = [
      {name: 'percent', value: 1, fill: colorCompleted},
      // {name: 'empty', value: 0, fill: styles.completed, fillOpacity:.25},
    ];
    
    return (
      <div className={styles.percentile}>
        <div className={styles.chartTitle}>{chartTitle}</div>
        <div className={styles.chartContainer}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={dataOutline} 
              dataKey='value'
              cx="50%"
              cy="50%"
              innerRadius="98%"
              outerRadius="100%"
              stroke="circle"
            /> 
            <Pie 
              data={data} 
              dataKey='value'
              cx="50%"
              cy="50%"
              innerRadius="72%"
              outerRadius="95%"
              stroke="circle"
              startAngle={90}
              endAngle={-270}
              >
            </Pie>
          </PieChart>
          </ResponsiveContainer>
          { 
            percent
            ? <div className={styles.score}>{metricValue}<span className={styles.percent}>%</span></div>
            : <div className={styles.score}>?</div>}
        </div>
      </div>
    );
  }
}

Percentile.propTypes = {
  percent: PropTypes.number,
  chartTitle: PropTypes.string,
  color: PropTypes.string,
};

export default Percentile;
