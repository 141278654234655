import React from 'react';
import PropTypes from 'prop-types';

import KatexText from '../KatexText';
import Button from '../Button';

import styles from './GridinQuestion.module.scss';

class GridinQuestion extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      answer: 'None'
    };
  }
 
  answerInput = () => {
    this.props.chooseAnswer(this.state.answer);
  }

  updateAnswer(event) {
    let value = event.target.value;
    if (/^[0-9.-]{1}[0-9./]{0,4}$/.test(value)) {
      if(value.length > 0) {
        this.setState({answer:value});
      }
      else {
        this.setState({answer:'None'});
      }
    }
    else {
      value = value.substring(0, 4);
      event.target.value = value.replace(/[^0-9./]+/g, '');
    }
  }
  
  render() {
    const { questionNumber, questionBody, selectedAnswer } = this.props;

    let val = '';
    if (selectedAnswer && selectedAnswer !== 'None') {
      val = selectedAnswer;
    }

    return (
      <div className={styles.question}>
        <div className={styles.questionBody}>
          <div className={styles.questionNumber}>{questionNumber}.</div>
          <KatexText text={questionBody} />
        </div>
        <div className={styles.answerInput}>
          <input type='number' onChange={this.updateAnswer.bind(this)} defaultValue={val} />
          <Button 
            onClick={this.answerInput.bind(this)}
            ctaHighlight={false}>
              Submit Answer
          </Button>
        </div>
      </div>
    );
  }
}

GridinQuestion.propTypes = {
  questionNumber: PropTypes.number.isRequired,
  questionBody: PropTypes.string.isRequired,
  answerOptions: PropTypes.object.isRequired,
  chooseAnswer: PropTypes.func.isRequired,
  selectedAnswer: PropTypes.string,
};

export default GridinQuestion;