const images = {
  logo: require('./images/logo.png'),
  logoText: require('./images/testive-text-black.png'),
  logoPoweredBy: require('./images/powered-by-testive.png'),
  logoSlyAndText: require('./images/testivelogo.svg'),
  appTitleLogo: require('./images/AppTitleLogo.png'),
  zoomIn: require('./images/plus.png'),
  zoomOut: require('./images/minus.png'),
  next: require('./images/next.svg'),
  back: require('./images/back.svg'),
  error: require('./images/error.svg'),
  check: require('./images/round-check.svg'),
  close: require('./images/close.svg'),
  calculator: require('./images/allow-calculator.svg'),
  noCalculator: require('./images/no-calculator.svg'),
  info: require('./images/info.svg'),
  timer: require('./images/timer.svg'),
  beta: require('./images/beta.svg'),
  math: require('./images/math.svg'),
  ela: require('./images/writing.svg')
};

export default images;