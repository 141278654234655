import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import EnsureLoggedIn from './components/EnsureLoggedIn';
import VerifyUser from './components/VerifyUser';
import WelcomeScreen from './components/WelcomeScreen';
import TestManager from './components/TestManager';
import TestResults from './components/TestResults';
import TestSectionNavigator from './components/TestSectionNavigator';
import Login from './components/Login';
import ChooseTest from './components/ChooseTest';

import styles from './app.module.scss';

class App extends Component {
  render() {
    
    return (
      <div className={styles.appMain}>     
        <BrowserRouter>
          <Switch>
            <Route exact path='/' component={VerifyUser} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/:test(SAT|ACT)/:id/' component={VerifyUser} />
            <Route exact path='/verify/:id/:test(SAT|ACT)?' component={VerifyUser} />
            <Route exact path='/choosetest' component={EnsureLoggedIn(ChooseTest)} />
            <Route exact path='/welcome/' component={EnsureLoggedIn(WelcomeScreen)} />
            <Route exact path='/sections/' component={EnsureLoggedIn(TestSectionNavigator)} />
            <Route exact path='/testsession/' component={EnsureLoggedIn(TestManager)} />
            <Route exact path='/results/:test(SAT|ACT)/:id?/' component={TestResults} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
