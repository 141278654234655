import * as constants from '../constants/actions';
import { format } from 'date-fns';
import * as emailjs from 'emailjs-com';

const server = 'https://app.testive.com';
// const server = 'http://127.0.0.1:8000';

export function setTestID(testID) {

  return {
    type: constants.SET_TEST_ID,
    testID: testID.toUpperCase()
  }
}

function startTestRequest() {
  return {
    type: constants.START_TEST_REQUEST
  };
}

function startTestSuccess(data, test, testSection) {
  return {
    type: constants.START_TEST_SUCCESS,
    test: test,
    currentQuestion: data.current_question,
    nextQuestionCorrect: data.future_right_question,
    nextQuestionIncorrect: data.future_wrong_question,
    questionCount: data.questions_completed,
    allowCalculator: data.allow_calculator,
    testSection: testSection,
  };
}

function startTestFailure(error, test, userUUID) {
  emailjs.send(
    'default_service', 
    'adaptive_test_start_error',
    {
      userUUID: userUUID,
      testID: test,
      error: JSON.stringify(error)
    }
  );

  return {
    type: constants.START_TEST_FAILURE,
    error: error,
    failureAt: Date.now()
  };
  
}

function existingTestError() {
  return {
    type: constants.EXISTING_TEST_ERROR
  };
}

export function startTest(userUUID, test, testSection) {
  return function (dispatch) {
    dispatch(startTestRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const rightNow = new Date();   // ('%Y-%m-%dT%H:%M:%SZ')
    const testStartedOn = format(rightNow, 'yyyy-MM-dd\'T\'HH:mm:ss') + 'Z';
  
    let postData = [];
    postData.push(encodeURIComponent('student') + '=' + encodeURIComponent(userUUID));
    postData.push(encodeURIComponent('test_identifier') + '=' + encodeURIComponent(test.v3TestIdentifier));
    postData.push(encodeURIComponent('test_section_id') + '=' + encodeURIComponent(testSection.v3TaxonomyKey));
    postData.push(encodeURIComponent('test_section_started_on') + '=' + encodeURIComponent(testStartedOn));
  
    return fetch(server+'/api/testsession/adaptivetest/start-test/', {
      method: 'POST',
      body: postData.join('&'),
      headers: header
    }).then(
      response => response.json(),
      error => dispatch(startTestFailure(error.message, test, userUUID))
    )
      .then(
        function(json) {
          if (json.status === 'ok') {
            dispatch(startTestSuccess(json.data, test, testSection));
          } else if(json.status === 'error'){
            if(json.msg.__all__ && json.msg.__all__.includes('Existing Test Found')) {
              dispatch(existingTestError());
            }
            else {
              dispatch(startTestFailure(json.msg, test, userUUID));
            }
          }
        }
      );
  };
}

export function completeTestSection(testHistory) {
  return {
    type: constants.COMPLETE_TEST_SECTION,
    testHistory: testHistory
  };
}

export function setExtendedTime(value) {
  return {
    type: constants.SET_EXTENDED_TIME,
    value: value,
  };
}

export function toggleInstructionModal() {
  return {
    type: constants.TOGGLE_INSTRUCTION_MODAL
  };
}

export function pauseSession(timeSaved) {
  return {
    type: constants.PAUSE_SESSION,
    timeSaved: timeSaved
  };
}

export function resumeSession() {
  return {
    type: constants.RESUME_SESSION
  };
}

export function setCurrentQuestion(question) {
  return {
    type: constants.SET_CURRENT_QUESTION,
    question: question
  };
}

function postAnswerRequest() {
  return {
    type: constants.POST_ANSWER_REQUEST,
  };
}

function postAnswerSuccess(data) {
  return {
    type: constants.POST_ANSWER_SUCCESS,
    currentQuestion: data.current_question,
    nextQuestionCorrect: data.future_right_question,
    nextQuestionIncorrect: data.future_wrong_question,
    isLastQuestion: data.is_last_question,
    isComplete: data.is_complete,
    questionCount: data.questions_completed,
    allowCalculator: data.allow_calculator
  };
}

function postAnswerFailure(error, userUUID, testID, questionID, answer) {
  console.log('postAnswerFailure ', error);
  emailjs.send(
    'default_service', 
    'adaptive_test_post_answer',
    {
      userUUID: userUUID,
      testID: testID,
      questionID: questionID,
      answer: answer,
      error: JSON.stringify(error)
    }
  );

  return {
    type: constants.POST_ANSWER_FAILURE,
    error: error,
    failureAt: Date.now()
  };
}

export function postAnswer(userUUID, test, testSection, question, answer, timeToAnswer, nextQuestion) {
  return function (dispatch) {

    // dispatch(setCurrentQuestion(nextQuestion));
    dispatch(postAnswerRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    
    let postData = [];
    postData.push(encodeURIComponent('student') + '=' + encodeURIComponent(userUUID));
    postData.push(encodeURIComponent('test_identifier') + '=' + encodeURIComponent(test.v3TestIdentifier));
    postData.push(encodeURIComponent('itinerary_key') + '=' + encodeURIComponent(testSection.v3TaxonomyKey));
    postData.push(encodeURIComponent('question_id') + '=' + encodeURIComponent(question.id));
    if (nextQuestion)
      postData.push(encodeURIComponent('current_question_id') + '=' + encodeURIComponent(nextQuestion.id));
    postData.push(encodeURIComponent('answer') + '=' + encodeURIComponent(answer));
    postData.push(encodeURIComponent('time_to_answer') + '=' + encodeURIComponent(timeToAnswer));
  
    return fetch(server+'/api/testsession/adaptivetest/post-answer/', {
      method: 'POST',
      body: postData.join('&'),
      headers: header
    }).then(
      response => response.json(),
      error => dispatch(postAnswerFailure(error.message))
    )
      .then(
        function(json) {
          if (json.status === 'ok') {
            dispatch(postAnswerSuccess(json.data));
            if (json.data.is_complete) {
              dispatch(completeTestSection(json.data.test_history))
            }
          } else if(json.status === 'error'){
            console.log(json);
            console.log(postData)
            dispatch(postAnswerFailure(json.msg));
          }
        }
      );
  };
}

function loadTestResultsRequest() {
  return {
    type: constants.LOAD_TEST_RESULTS_REQUEST
  };
}

function loadTestResultsSuccess(data) {

  return {
    type: constants.LOAD_TEST_RESULTS_SUCCESS,
    receivedAt: Date.now(),
    data: data,
  };
}

function loadTestResultsFailure(error) {
  return {
    type: constants.LOAD_TEST_RESULTS_FAILURE,
    error: error,
    failureAt: Date.now()
  };
}

export function loadTestResults(userUUID, test) {
  return function (dispatch) {
    dispatch(loadTestResultsRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return fetch(server+'/api/testsession/adaptivetest/view-results/?student='+userUUID+'&test_identifier='+test.v3TestIdentifier, {
      headers: header
    }).then(
      response => response.json(),
      error => dispatch(loadTestResultsFailure(error.message))
    )
      .then(
        function(json) {
          if (json.status === 'ok') {
            dispatch(loadTestResultsSuccess(json.data));
          } else if(json.status === 'error'){
            dispatch(loadTestResultsFailure(json.msg));
          }
        }
      );
  };
}