import React from 'react';
import PropTypes from 'prop-types';
import KatexText from './../KatexText';

import styles from './Answer.module.scss';
import cx from 'classnames';

class Answer extends React.Component {

  handleClick = () =>  {
    this.props.answerClicked(this.props.answerID);
  }

  render() {
    const { answerID, answerText, selected, isDesktop } = this.props;
    
    const answerMap = {1:'A', 2:'B', 3:'C', 4:'D', 5:'E'};

    const answerStyle = (selected)
      ? cx(styles.answer, styles.selected)
      : styles.answer;

    if(isDesktop) {
      return (
        <button className={answerStyle} onMouseDown={this.handleClick}>
          <span className={styles.answerPrefix}>{answerMap[answerID]}.</span> 
          <KatexText text={answerText} />
        </button>
      );
    }
    else {
      return (
        <button className={answerStyle} onClick={this.handleClick}>
          <span className={styles.answerPrefix}>{answerMap[answerID]}.</span> 
          <KatexText text={answerText} />
        </button>
      );
    }
  }
}

Answer.propTypes = {
  answerID: PropTypes.number.isRequired,
  answerText: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  answerClicked: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool.isRequired,
};

Answer.defaultProps = {
  selected: false
};

export default Answer;