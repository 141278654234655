import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './Login.module.scss';
import { userLogin } from './../../actions';
import Images from '../../assets/Images';


class Login extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      email: props.email,
      loggedIn: false,
    };
    
    this.onLogin = this.onLogin.bind(this);
  }
   
  onLogin(event) {
    event.preventDefault();
    event.stopPropagation();

    this.props.login(this.state.email);
    this.setState({
      loggedIn: true
    });
  }
  
  // componentDidUpdate() {
  //   if(this.props.email) {
  //     this.props.history.replace('/welcome');
  //   }
  // }

  // componentDidMount() {
  //   if(this.props.email){
  //     this.props.history.replace('/welcome');
  //   }
  //   let params = new URLSearchParams(window.location.search);
  //   if(params.get('email')) {
  //     this.props.login(params.get('email'));
  //   }
  // }
  
  render() {

    return (
      <div className={styles.login}>
        <div className={styles.mainContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.name}>Testive Express Test</div>
          </div>
        
          { this.state.loggedIn ?
            <div className={styles.loggedIn}>Please check your email for your log in link.</div>
            :
            <form className={styles.formContainer} onSubmit={this.onLogin}>
            <input 
              name='email'
              type='email' 
              autoComplete='email'
              placeholder='enter your email'
              maxLength='75'
              required
              onChange={(e) => this.setState({email: e.target.value})}
              value={this.state.email}>
            </input>
        
            <div className={styles.buttonContainer}>
              <input type='submit' value='START'></input>
            </div>
            </form>
          }

          <img src={Images.logoPoweredBy} alt='testive'></img>
        </div>

      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  history: PropTypes.object,
  email: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    email: state.user.email
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: request => dispatch(userLogin(request)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);