import React from 'react';
import PropTypes from 'prop-types';
import styles from './PassageTest.module.scss';
import Passage from '../Passage';
import Question from '../Question';


class PassageTest extends React.Component {

  chooseAnswer = (answerID) => {
    this.props.chooseAnswer(answerID);
  }

  render() {
    const { question, questionNumber } = this.props;

    return (
      <div className={styles.passageTest}>
        <div className={styles.passage}>
          <Passage
            passage={question.passage}
          />
        </div>
        <div className={styles.question}>
          <Question
            questionNumber={questionNumber}
            questionBody={question.body}
            answerOptions={question.answers}
            chooseAnswer={this.chooseAnswer}
          />
        </div>
      </div>
    );

  }
}

PassageTest.propTypes = {
  question: PropTypes.object.isRequired,
  questionNumber: PropTypes.number.isRequired,
  chooseAnswer: PropTypes.func.isRequired
};

export default PassageTest;