import React, {Component} from 'react';
import {connect} from 'react-redux';
// import {saveProps} from '../../actions';

export default function(ComposedClass){
  class EnsureLoggedIn extends Component{
    
    constructor(props){
      super(props);
      // let params = props.match.params;
      // if(Object.keys(params).length > 0){
      //   props.saveProps(params);
      // }
      if(!props.isAuthenticated){
        props.history.replace('/login');
      }
    }
    
    render(){
      if (this.props.isAuthenticating)
        return (<div>authenticating...</div>);
      else if (this.props.isAuthenticated)
        return (<ComposedClass {...this.props}/>);
      else
        return null;
    }
    
  }
  
  function mapStateToProps(state){
    return {
      isAuthenticated : state.user.isAuthenticated,
      isAuthenticating : state.user.isAuthenticating
    };
  }

  function mapDispatchToProps(dispatch){
    return {
      // saveProps: props => dispatch(saveProps(props))
    };
  }
  
  return connect(mapStateToProps, mapDispatchToProps)(EnsureLoggedIn);
}