import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loadTestResults, startTest } from '../../actions';

import styles from './TestSectionNavigator.module.scss';

import TestSection from '../TestSection';
import WaitForIt from '../WaitForIt';
import ErrorMessage from '../ErrorMessage';
import AppTitle from '../AppTitle'

class TestSectionNavigator extends React.Component {

  componentDidMount() {
    const { userUUID, test } = this.props;
    this.props.loadTestResults(userUUID, test);
  }

  runTestSection = (section) => {
    const { test, userUUID } = this.props;
    this.props.startTest(userUUID, test, section);
    this.props.history.replace('/testsession/');
  }

  render() {
    const { 
      test, 
      error, 
      isFetchingResults,
      sections
    } = this.props;

    if (isFetchingResults) {
      return <WaitForIt waitingMessage='Loading test info'/>;
    }

    const body = error 
      ? <ErrorMessage errors={error}/> 
      : test.sections.map((s,i) => 
        {
          return (
            <TestSection 
              key={i}
              section={s}
              userSectionData={sections[s.subject]}
              runTestSection={this.runTestSection}
              color={s.color}/>
            );
        }); 
  
    return( 
      <div className={styles.testSectionNavigator}>
        <div className={styles.titleBar}>
          <div className={styles.testTitle}><AppTitle/></div>
        </div>
        <div className={styles.testSections}>{body}</div>
      </div> 
    );
  }
}

TestSectionNavigator.propTypes = {
  isFetchingResults: PropTypes.bool.isRequired,
  history: PropTypes.object,
  test: PropTypes.object.isRequired,
  error: PropTypes.object,
  userUUID: PropTypes.string.isRequired,
  loadTestResults: PropTypes.func.isRequired,
  startTest: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    error: state.results.error,
    isFetchingResults: state.results.isFetching,
    testSessionState: state.testsession.testSessionState,
    test: state.testsession.test,
    userUUID: state.user.userUUID,
    sections: state.results.sections
  };
}

function mapDispatchToProps(dispatch) {
  return {
    startTest: (userUUID, test, testSection) => dispatch(startTest(userUUID, test, testSection)),
    loadTestResults: (userUUID, test) => dispatch(loadTestResults(userUUID, test)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestSectionNavigator); 