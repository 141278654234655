import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Timer.module.scss';

class Timer extends React.Component {
  constructor(props){
    super(props);
    
    const totalTime = props.timeSaved ? props.timeSaved : props.timeInSeconds;
    this.state = {
      initialTime: totalTime,
      timeInSeconds: totalTime
    };
  
    this.intervalID = window.setInterval(this.count, 1000);
  }

  count = () => {
    const { stopwatchMode } = this.props;
    let { timeInSeconds } = this.state;


    timeInSeconds =  stopwatchMode ? 
      timeInSeconds += 1
      : timeInSeconds -=1;

    const timerExpired = stopwatchMode
      ? false
      : (timeInSeconds < 0);

    if (!timerExpired) {
      this.props.notifyParent(timeInSeconds);
      this.setState({
        timeInSeconds: timeInSeconds
      });
    }
    else {
      this.props.onTimerComplete();
    }
  }

  formatNumber(num) {
    //Zero pad numbers
    num = num.toString();
    if(num.length < 2) {
      num = '0' + num;
    }
    return num;
  }

  getTimerStyle(timeInSeconds) {
    const { 
      stopwatchMode,
      firstWarning, 
      secondWarning, 
      finalWarning, } = this.props;

    let timerStyle = styles.number;
    if (stopwatchMode) {
      if (timeInSeconds > finalWarning) {
        timerStyle = cx(timerStyle, styles.finalWarning);
      }
      else if (timeInSeconds >= secondWarning) {
        timerStyle = cx(timerStyle, styles.secondWarning);
      }
      else if (timeInSeconds >= firstWarning) {
        timerStyle = cx(timerStyle, styles.firstWarning);
      }
    }
    else {
      if (timeInSeconds <= finalWarning) {
        timerStyle = cx(timerStyle, styles.finalWarning);
      }
      else if (timeInSeconds <= secondWarning) {
        timerStyle = cx(timerStyle, styles.secondWarning);
      }
      else if (timeInSeconds <= firstWarning) {
        timerStyle = cx(timerStyle, styles.firstWarning);
      }
    }
    return timerStyle;

  }

  render() {
    const { timeInSeconds }  = this.state;
    const { timerText, style, showMinutes } = this.props;

    const timerStyle = this.getTimerStyle(timeInSeconds);
    
    let remainingMinutes = Math.floor(timeInSeconds / 60);
    let remainingSeconds = timeInSeconds % 60;
    const formatted = showMinutes ?
      remainingMinutes+':'+this.formatNumber(remainingSeconds)
      :remainingSeconds;

    return (
      <div className={styles.timer} style={style}>
        <span className={styles.text}>{timerText}</span> 
        <div className={timerStyle}>
          {formatted}
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    window.clearInterval(this.intervalID);
  }
}

Timer.propTypes = {
  timeInSeconds: PropTypes.number.isRequired,
  onTimerComplete: PropTypes.func.isRequired,
  firstWarning: PropTypes.number.isRequired,
  secondWarning: PropTypes.number.isRequired,
  finalWarning: PropTypes.number.isRequired,
  notifyParent: PropTypes.func.isRequired,
  currentTime: PropTypes.number,
  timerText: PropTypes.string,
  style: PropTypes.object,
  timeSaved: PropTypes.number,
  showMinutes: PropTypes.bool.isRequired,
  stopwatchMode: PropTypes.bool.isRequired
};

Timer.defaultProps = {
  timeInSeconds:0,
  firstWarning:1200,
  secondWarning:1500,
  finalWarning:1800,
  showMinutes:true,
  stopwatchMode:false
};

export default Timer;
