import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { completeTestSection } from '../../actions';
import test from '../../constants/test';

// import Images from '../../assets/Images';
import Timer from '../Timer';
import Button from '../Button';

import styles from './TestBreak.module.scss';

const timerStyle = {
  color: 'white',
  width: 'fit-content',
  fontSize: '100px',
};

class TestBreak extends React.Component {
  
  constructor(props) {
    super(props);

    let elapsedTime = 0;
    if(props.timerStart instanceof Date) {
      const timeDiffMs = new Date().getTime() - props.timerStart.getTime();
      elapsedTime = Math.floor(timeDiffMs/1000);
    }

    this.remainingTime = props.currentStep.time - elapsedTime;
  }

  onBreakComplete = () => {
    this.props.completeTestSection();
  }

  render() {
    const { test, currentStepIndex } = this.props;

    const nextSection = test[currentStepIndex+1].subject;
 
    var timer = <Timer
      notifyParent={function() {}}
      style={timerStyle}
      timeInSeconds={this.remainingTime}
      // timeInSeconds={600}
      finalWarning={0}
      showMinutes={false}
      onTimerComplete={this.onBreakComplete}
      timerText=''/>;

    return (
      <div className={styles.testBreak}>
        <div className={styles.mainContainer}>
          <div className={styles.title}>{nextSection}</div>
          <div className={styles.subTitle}>starts in</div>
          <div className={styles.timer}>
            {timer}
          </div>
          <div className={styles.subTitle}>seconds</div>
          <div className={styles.buttonContainer}>
            <Button
              onClick={this.onBreakComplete}>
              START NOW
            </Button>
          </div>
          
        </div>
      </div>
    );
  }  
}
    
TestBreak.propTypes = {
  test: PropTypes.array.isRequired,
  currentStepIndex: PropTypes.number.isRequired,
  currentStep: PropTypes.object,
  currentTimer: PropTypes.number,
  completeTestSection: PropTypes.func,
  timerStart: PropTypes.instanceOf(Date)
};

TestBreak.defaultProps = {
  test: test,
};
    
function mapStateToProps(state) {
  const { testsession } = state;

  return {
    currentStep: testsession.currentStep,
    currentStepIndex: testsession.currentStepIndex,
    currentTimer: testsession.timeSaved,
    timerStart: testsession.timerStart
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    completeTestSection: request => dispatch(completeTestSection(request)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestBreak);