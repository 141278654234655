import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
  // updateSessionState,
  postAnswer, 
  setCurrentQuestion,
  pauseSession, 
  completeTestSection,
  toggleInstructionModal } from '../../actions';
import PassageTest from './../PassageTest';
import MathTest from '../MathTest';
import TestHeader from '../TestHeader';
import styles from './TestSession.module.scss';
import TestSectionDirections from '../TestSectionDirections';
import ErrorMessage from '../ErrorMessage';
import WaitForIt from '../WaitForIt';

class TestSession extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      currentTime: 0,
      lastQuestionAnswered: 0,
      waitingToPost: false,
      answer: -1,
      showCalculatorModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    const state = this.state;
    const props = this.props;

    if(state.waitingToPost) {
      if(!props.isFetching) {
        this.postQuestion(state.answer);
      }
    }

    if(props.allowCalculator && !prevProps.allowCalculator) {
      this.setState({
        showCalculatorModal: true
      });
    }
  }

  // getTestSectionRemainingTime() {
  //   const { section, timeSaved } = this.props;
  //   return timeSaved ? timeSaved : section.time;
  // }

  // getTotalSectionMinutes() {
  //   const { section } = this.props;
  //   return Math.floor(section.time/60);
  // }

  pauseSession = () => {
    this.props.pauseSession(this.state.currentTime);
    this.props.history.replace('/pause');
  }

  completeSection = (testAnswers) => {
    this.props.completeTestSection(testAnswers);
  }

  updateTime = (time) => {
    this.setState({
      currentTime: time
    });
  }

  answerQuestion = (answer) => {
    this.setState({
      waitingToPost: true,
      answer: answer
    });
  }
  
  postQuestion = (answer) => {
    const { 
      userUUID, 
      test, 
      testSection,
      currentQuestion,
      nextQuestionCorrect,
      nextQuestionIncorrect,
      isLastQuestion,
      postAnswer,
      setCurrentQuestion
    } = this.props;

    const { lastQuestionAnswered, currentTime } = this.state;

    // set the next question based on the answer
    const nextQuestion = currentQuestion.answerCorrect === answer
      ? nextQuestionCorrect : nextQuestionIncorrect;

    if (!isLastQuestion)
    {
      setCurrentQuestion(nextQuestion);
    }

    const timeToAnswer = currentTime - lastQuestionAnswered;
    this.setState({
      lastQuestionAnswered: currentTime,
      waitingToPost: false
    });

    postAnswer(
      userUUID, 
      test, 
      testSection,
      currentQuestion, 
      answer, 
      timeToAnswer, 
      nextQuestion);
  }

  renderTestSectionDirections(sectionTitle) {
    const { toggleInstructionModal } = this.props;

    return (
      <TestSectionDirections
        testTitle={sectionTitle}
        onDismiss={toggleInstructionModal}
      ></TestSectionDirections>
    );
  }

  renderCalculatorModal() {
    return (
      <TestSectionDirections
        testTitle={'math'}
        onDismiss={() => this.setState({showCalculatorModal: false})}
      />
    );
  }

  render() {
    const { 
      timeSaved, 
      currentQuestion, 
      isFetching,
      sessionError,
      currentQuestionNumber,
      showInstructionModal,
      allowCalculator,
      testSection
    } = this.props;
   
    const waitingToPost = this.state.waitingToPost;
    const showCalculatorModal = this.state.showCalculatorModal;
  
    if (sessionError) {
      return (<ErrorMessage errors={sessionError}/>);
    }

    if (waitingToPost && isFetching) {
      return (
        <WaitForIt waitingMessage='Loading your next question' />
      );
    }

    const testSectionComponent = (testSection.subject === 'math')
      ? <MathTest 
        questionNumber={currentQuestionNumber}
        question={currentQuestion}
        chooseAnswer={this.answerQuestion}
      />
      : <PassageTest
        questionNumber={currentQuestionNumber}
        question={currentQuestion}
        chooseAnswer={this.answerQuestion}
      />;

    const directionsModal = showInstructionModal 
      ? this.renderTestSectionDirections(testSection.subject) 
      : null;

    const calculatorModal = showCalculatorModal
      ? this.renderCalculatorModal()
      : null;

    return (
      <div className={styles.testSession}>
        <TestHeader 
          section={testSection}
          timeSaved={timeSaved}
          pauseSession={this.pauseSession}
          updateTime={this.updateTime}
          completeSection={this.completeSection}
          showTimer={!showInstructionModal} />
        {testSectionComponent}
        {directionsModal}
        {calculatorModal}
      </div>
    );
  }
}

TestSession.propTypes = {
  testSection: PropTypes.object.isRequired,
  postAnswer: PropTypes.func.isRequired,
  setCurrentQuestion: PropTypes.func.isRequired,
  pauseSession: PropTypes.func.isRequired,
  completeTestSection: PropTypes.func.isRequired,
  timeSaved: PropTypes.number,
  currentQuestion: PropTypes.object,
  nextQuestionCorrect: PropTypes.object,
  nextQuestionIncorrect: PropTypes.object,
  history: PropTypes.object,
  allowCalculator: PropTypes.bool,
  showInstructionModal: PropTypes.bool.isRequired,
  toggleInstructionModal: PropTypes.func.isRequired,
  userUUID: PropTypes.string.isRequired,
  test: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  currentQuestionNumber: PropTypes.number,
  sessionError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  testSessionState: PropTypes.string,
  isLastQuestion: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    userUUID: state.user.userUUID,
    test: state.testsession.test,
    isFetching: state.testsession.isFetching,
    sessionError: state.testsession.error,
    timeSaved: state.testsession.timeSaved,
    currentQuestionNumber: state.testsession.currentQuestionNumber,
    currentQuestion: state.testsession.currentQuestion,
    nextQuestionCorrect: state.testsession.nextQuestionCorrect,
    nextQuestionIncorrect: state.testsession.nextQuestionIncorrect,
    allowCalculator: state.testsession.allowCalculator,
    showInstructionModal: state.testsession.showInstructionModal,
    testSessionState: state.testsession.testSessionState,
    isLastQuestion: state.testsession.isLastQuestion,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    postAnswer: (userUUID, test, testSection, question, answer, timeToAnswer, nextQuestion) => dispatch(postAnswer(userUUID, test, testSection, question, answer, timeToAnswer, nextQuestion)),
    pauseSession: (timeSaved) => dispatch(pauseSession(timeSaved)),
    completeTestSection: (testAnswers) => dispatch(completeTestSection(testAnswers)),
    setCurrentQuestion: (nextQuestion) => dispatch(setCurrentQuestion(nextQuestion)),
    toggleInstructionModal: (request) => dispatch(toggleInstructionModal(request)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestSession); 