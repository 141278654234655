export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';

export const LOGOUT = 'LOGOUT';
export const USER_VERIFY_REQUEST = 'USER_VERIFY_REQUEST';
export const USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS'; 
export const USER_VERIFY_FAILURE = 'USER_VERIFY_FAILURE';

export const PAUSE_SESSION = 'PAUSE_SESSION';
export const RESUME_SESSION = 'RESUME_SESSION';
export const COMPLETE_TEST_SECTION = 'COMPLETE_TEST_SECTION';

export const SET_TEST_ID = 'SET_TEST_ID';
export const SET_EXTENDED_TIME = 'SET_EXTENDED_TIME';
export const TOGGLE_INSTRUCTION_MODAL = 'TOGGLE_INSTRUCTION_MODAL';
export const TEST_ANALYTICS = 'TEST_ANALYTICS';

export const START_TEST_REQUEST= 'START_TEST_REQUEST';
export const START_TEST_SUCCESS= 'START_TEST_SUCCESS';
export const START_TEST_FAILURE= 'START_TEST_FAILURE';
export const EXISTING_TEST_ERROR = 'EXISTING_TEST_ERROR';

export const POST_ANSWER_REQUEST = 'POST_ANSWER_REQUEST';
export const POST_ANSWER_SUCCESS = 'POST_ANSWER_SUCCESS';
export const POST_ANSWER_FAILURE = 'POST_ANSWER_FAILURE';

export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION';

export const LOAD_TEST_RESULTS_REQUEST = 'LOAD_TEST_RESULTS_REQUEST';
export const LOAD_TEST_RESULTS_SUCCESS = 'LOAD_TEST_RESULTS_SUCCESS';
export const LOAD_TEST_RESULTS_FAILURE = 'LOAD_TEST_RESULTS_FAILURE';

export const SAVE_PROPS = 'SAVE_PROPS';
export const CLEAR_PROPS = 'CLEAR_PROPS';