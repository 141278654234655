import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from'./Button.module.scss';

class Button extends React.Component {
  handleClick(e) {
    e.preventDefault();

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    const { 
      children, 
      className, 
      ctaHighlight,
      disabled,
      transparent,
      smallButton
    } = this.props;

    let cx = classNames.bind(styles);
    let buttonStyle = cx({
      button: true,
      disabled: disabled,
      cta: ctaHighlight,
      transparent: transparent,
      small: smallButton,
    });
    return (
      <button
        className={className !== undefined ? className : buttonStyle}
        disabled={this.props.disabled || false}
        onClick={(e) => this.handleClick(e)}>
        {children}
      </button>
    );
  }
}

Button.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool.isRequired,
  ctaHighlight: PropTypes.bool.isRequired,
  transparent: PropTypes.bool.isRequired,
  small: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  
};

Button.defaultProps = {
  ctaHighlight: true,
  disabled: false,
  transparent: false,
  small: false,
};

export default Button;
