import React from 'react';
import { parseISO, format } from 'date-fns';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ScoreSummary from '../Charts/ScoreSummary';
import PercentileSummary from '../Charts/PercentileSummary';
import WaitForIt from '../WaitForIt';
import { verifyUser, loadTestResults } from '../../actions';

import Images from '../../assets/Images';
import styles from './TestResults.module.scss';

class TestResults extends React.Component {
  
  componentDidMount() {
    const userUUID = this.props.match.params.id;
    const testID = this.props.match.params.test;

    this.props.verifyUser(userUUID);
    this.props.loadTestResults(userUUID, testID);
  }

  renderTestInfo() {
    const { displayName, startedAt, completedAt } = this.props;
    const testID = this.props.match.params.test;

    let startTime = format(parseISO(startedAt), 'M/d h:m aa');
    let endTime = format(parseISO(completedAt), 'M/d h:m aa');
    
    return (
      <div className={styles.infoHeader}>
        <div className={styles.infoItem}>
          <span>Student:</span> {displayName}
        </div>
        <div className={styles.infoItem}>
          <span>Test:</span> Adaptive {testID} - Math Section
        </div>
        <div className={styles.infoItem}>
          <span>Start Time:</span> {startTime}
        </div>
        <div className={styles.infoItem}>
          <span>End Time:</span> {endTime}
        </div>

      </div>
    );
  }


  render() {
    const { isFetching, 
      match, 
      isAuthenticated, 
      score, 
      totalTime, 
      averageTime } = this.props;
    const testID = this.props.match.params.test;

    if (!match.params.id) {
      return (
        <div className={styles.loading}>
          No test results found
        </div>
      );
    }
    console.log('fetching', isFetching, score);
    if (isFetching) {
      return (
        <div className={styles.loading}>
          <WaitForIt waitingMessage='Loading results' />
        </div>
      );
    }
    if (!isAuthenticated) {
      return (
        <div className={styles.loading}>
          <WaitForIt waitingMessage='Authenticating' />
        </div>
      );
    }

    return (
      <div className={styles.testResults}>
        <div className={styles.mainContainer}>
          <div className={styles.header}>
            <img src={Images.logoSlyAndText} alt='testive'></img>
            {this.renderTestInfo()}
          </div>
          <div className={styles.resultsHeader}>Your results</div>
          <div className={styles.score}>
            <ScoreSummary 
              testID={testID}
              mathScore={score}>
            </ScoreSummary>
          </div>
          <div className={styles.resultsHeader}>Percentiles</div>
          <div className={styles.score}>
            <PercentileSummary 
              testID={testID}
              math={.2}>
            </PercentileSummary>
          </div>
          <div className={styles.breakdown}>
            <div className={styles.subHeader}>Score Breakdown</div>
            <div className={styles.time}>
              <div className={styles.timeItem}><span>Total Time:</span> {totalTime}</div>
              <div className={styles.timeItem}><span>Average Per Question:</span> {averageTime}</div>
            </div>
            
          </div>
        </div>
      </div>
    );
  }
}

TestResults.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  loadTestResults: PropTypes.func.isRequired,
  verifyUser: PropTypes.func.isRequired,
  match: PropTypes.object,
  userEmail: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  history: PropTypes.object,
  score: PropTypes.number,
  totalTime: PropTypes.number,
  averageTime: PropTypes.number,
  percentCorrect: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    isFetching: state.results.isFetching,
    isAuthenticated: state.user.isAuthenticated,
    displayName: state.user.displayName,
    userEmail: state.user.email,
    score: state.results.score,
    startedAt: state.results.startedAt,
    completedAt: state.results.completedAt,
    totalTime: state.results.totalTime,
    averageTime: state.results.averageTime,
    percentCorrect: state.results.percentCorrect,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadTestResults: (userUUID, testID) => dispatch(loadTestResults(userUUID, testID)),
    verifyUser: (userUUID) => dispatch(verifyUser(userUUID))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestResults);