import * as constants from '../constants/actions';

const initialState = {
  isFetching: true,
  error: undefined,
  // testResults: undefined
};

function convertSectionResults(sectionData) {
  return {
    totalTime: sectionData.total_time,
    avgTime: sectionData.avg_time,
    startedOn: sectionData.started_on,
    completedOn: sectionData.completed_on,
    totalQuestions: sectionData.total_questions,
    isCompleted: sectionData.is_completed,
    score: sectionData.score,
    questionsCompleted: sectionData.question_count
  };
};

export default function testsession(state = initialState, action) {
  switch (action.type) {
      
    case constants.LOAD_TEST_RESULTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        error: undefined,
      });

    case constants.LOAD_TEST_RESULTS_SUCCESS:
      var data = action.data['SAT'];

      // const testSections = Object.values(data.sections).map((section, index) => {
      //   console.log(section);
      //   return (convertSectionResults(section));
      // });

      return Object.assign({}, state, {
        isFetching: false,
        error: undefined,

        state: data.state, //'NOTSTARTED',
        startedOn: data.started_on,
        isComplete: data.is_completed,
        completedOn: data.completed_on,
        score: data.score,
        sections: data.sections
      });

    case constants.LOAD_TEST_RESULTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });

    default:
      return state;
  }
}