import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TestSession from '../TestSession';
import TestBreak from '../TestBreak';
import WaitForIt from '../WaitForIt';
import ErrorMessage from '../ErrorMessage';

class TestManager extends React.Component {

  componentDidUpdate() {
    const { testSessionState } = this.props;

    if (testSessionState === 'COMPLETED') {
      this.props.history.replace('/sections/');
    }
  }

  render() {
    const { 
      testSection, 
      error, 
      testSessionState,
      isFetchingTest
    } = this.props;

    if (error) {
      return (<ErrorMessage errors={error}/>);
    }
    else if (testSessionState === 'BREAK') {
      return <TestBreak />;
    }
    else if (isFetchingTest) {
      return <WaitForIt waitingMessage='starting up your test session'/>;
    }
    else if (testSessionState === 'ACTIVE') {
      return <TestSession 
        testSection={testSection} 
        history={this.props.history}/>;
    }
    else {
      return <WaitForIt waitingMessage='Loading your test'/>;
    }
    
  }
}

TestManager.propTypes = {
  history: PropTypes.object.isRequired,
  testSessionState: PropTypes.string.isRequired,
  error: PropTypes.object,
  isFetchingTest: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    testSection: state.testsession.testSection,
    testSessionState: state.testsession.testSessionState,
    error: state.testsession.error,
    isFetchingTest: state.testsession.isFetchingTest
  };
}

export default connect(mapStateToProps)(TestManager);