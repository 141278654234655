import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';


import AppTitle from '../AppTitle'
import { setTestID } from './../../actions';

import styles from './ChooseTest.module.scss';

class ChooseTest extends React.Component {

  chooseTest(test) {
    this.props.setTestID(test);
    this.props.history.push('/welcome');
  }

  render() {
    return (
      <div className={styles.chooseTest}>
        <AppTitle />
        <div className={styles.header}>Choose Your Test</div>
        <div className={styles.testContainer}>
          <div className={cx(styles.test, styles.sat)} onClick={() => this.chooseTest('SAT')}>SAT</div>
          <div className={cx(styles.test, styles.act)} onClick={() => this.chooseTest('ACT')}>ACT</div>
        </div>
      </div>
    );
  }
}

ChooseTest.propTypes = {
  setTestID: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setTestID: (testID) => dispatch(setTestID(testID))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseTest);