import * as constants from '../constants/actions';
import test from '../constants/test';

let testID = localStorage.getItem('testID');
let testSection = JSON.parse(localStorage.getItem('testSection'));

let timeSaved = parseInt(localStorage.getItem('timeSaved'));
let timerStart = localStorage.getItem('timerStart');
timerStart = timerStart ? new Date(timerStart) : undefined;
let extendedTime = JSON.parse(localStorage.getItem('extendedTime'));

let showInstructionModal = JSON.parse(localStorage.getItem('showInstructionModal'));

let testSessionState = localStorage.getItem('testSessionState');
let currentQuestion = JSON.parse(localStorage.getItem('currentQuestion'));
let currentQuestionNumber = parseInt(localStorage.getItem('currentQuestionNumber'));
let nextQuestionCorrect = JSON.parse(localStorage.getItem('nextQuestionCorrect'));
let nextQuestionIncorrect = JSON.parse(localStorage.getItem('nextQuestionIncorrect'));
let isLastQuestion = JSON.parse(localStorage.getItem('isLastQuestion'));
isLastQuestion = isLastQuestion ? isLastQuestion : false

const initialState = {

  isFetchingTest: false,
  isFetching: false,
  testID: testID ? testID : undefined,
  test: testID ? test[testID]: undefined,
  testSection: testSection ? testSection: undefined,

  testSessionState: testSessionState ? testSessionState : 'NOTSTARTED',
  currentQuestionNumber: currentQuestionNumber ? currentQuestionNumber : 0,
  currentQuestion: currentQuestion,
  nextQuestionCorrect: nextQuestionCorrect,
  nextQuestionIncorrect: nextQuestionIncorrect,
  allowCalculator: false,

  extendedTime: extendedTime ? extendedTime : false,
  showInstructionModal: showInstructionModal != null ? showInstructionModal : true,
  timeSaved: timeSaved,

  timerStart: timerStart,
  error: undefined,
  isLastQuestion: isLastQuestion ? isLastQuestion : false
};

export default function testsession(state = initialState, action) {
  switch (action.type) {
      
    case constants.LOGOUT: 
      return Object.assign({}, state, initialState);

    case constants.SET_TEST_ID:
      // ACT vs SAT
      localStorage.setItem('testID', action.testID);
     
      return Object.assign({}, state, {
        testID: action.testID,
        test: test[action.testID]
      });

    case constants.SET_EXTENDED_TIME:
      localStorage.setItem('extendedTime', action.value);
      return Object.assign({}, state, {
        extendedTime: action.value
      });

    case constants.TOGGLE_INSTRUCTION_MODAL:
      var show = !state.showInstructionModal;
      localStorage.setItem('showInstructionModal', show);
      return Object.assign({}, state, {
        showInstructionModal: show
      });

    case constants.START_TEST_REQUEST: 
      return Object.assign({}, state, {
        isFetchingTest: true,
        error: undefined ,
        testSessionState: 'NOTSTARTED'
      });

    case constants.START_TEST_FAILURE:
      return Object.assign({}, state, {
        isFetchingTest: false,
        error: action.error,
      });
    
    case constants.EXISTING_TEST_ERROR:
      return Object.assign({}, state, {
        isFetchingTest: false,
        error: undefined,
        testSessionState: 'COMPLETED'
      });

    case constants.START_TEST_SUCCESS:

      localStorage.setItem('testSessionState', 'ACTIVE');
      localStorage.setItem('testSection', JSON.stringify(action.testSection));
      localStorage.setItem('currentQuestionNumber', action.questionCount+1);
      localStorage.setItem('currentQuestion',  JSON.stringify(action.currentQuestion));
      localStorage.setItem('nextQuestionCorrect',  JSON.stringify(action.nextQuestionCorrect));
      localStorage.setItem('nextQuestionIncorrect',  JSON.stringify(action.nextQuestionIncorrect));

      return Object.assign({}, state, {
        isFetchingTest: false,
        error: undefined,
        testSessionState: 'ACTIVE',
        allowCalculator: action.allowCalculator,
        currentQuestionNumber: action.questionCount+1,
        currentQuestion: action.currentQuestion,
        nextQuestionCorrect: action.nextQuestionCorrect,
        nextQuestionIncorrect: action.nextQuestionIncorrect, 
        testSection: action.testSection
      });

    case constants.SET_CURRENT_QUESTION:
      localStorage.setItem('currentQuestionNumber', state.currentQuestionNumber+1);
      localStorage.setItem('currentQuestion', JSON.stringify(action.question));
      localStorage.setItem('nextQuestionCorrect', undefined);
      localStorage.setItem('nextQuestionIncorrect', undefined);
      return Object.assign({}, state, {
        currentQuestionNumber: state.currentQuestionNumber+1,
        currentQuestion: action.question,
        nextQuestionCorrect: undefined,
        nextQuestionIncorrect: undefined,
      });

    case constants.POST_ANSWER_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        error: undefined,
      });

    case constants.POST_ANSWER_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
      });

    case constants.POST_ANSWER_SUCCESS:
      localStorage.setItem('nextQuestionCorrect',  JSON.stringify(action.nextQuestionCorrect));
      localStorage.setItem('nextQuestionIncorrect',  JSON.stringify(action.nextQuestionIncorrect));
      localStorage.setItem('isLastQuestion', action.isLastQuestion);
      
      return Object.assign({}, state, {
        isFetching: false,
        error: undefined, 
        nextQuestionCorrect: action.nextQuestionCorrect,
        nextQuestionIncorrect: action.nextQuestionIncorrect,
        isLastQuestion: action.isLastQuestion,
        allowCalculator: action.allowCalculator,
        timerStart: action.session_time
      });

    case constants.PAUSE_SESSION:
      localStorage.setItem('timeSaved', action.timeSaved);
      
      return Object.assign({}, state, {
        testSessionState: 'PAUSED',
        timeSaved: action.timeSaved,
      });

    case constants.RESUME_SESSION:
      return Object.assign({}, state, {
        testSessionState: 'ACTIVE',
      });
     
    case constants.COMPLETE_TEST_SECTION: 
      localStorage.setItem('testSessionState', 'COMPLETED');
      return Object.assign({}, state, {
        testSessionState: 'COMPLETED',
      });

    default:
      return state;
  }
}