import { combineReducers } from 'redux';
import user from './user';
import testsession from './testsession';
import results from './results';

const appReducer = combineReducers({
  user,
  testsession,
  results
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;