import React from 'react';
import PropTypes from 'prop-types';

import styles from './Passage.module.scss';
class Passage extends React.Component {

  constructor(props) {
    super(props);
    let passage_with_numbers = props.passage;
    passage_with_numbers[1] = "<div>1</div>".concat(passage_with_numbers[1]);
    for(let i = 2; i < passage_with_numbers.length; i++) {
      if(i % 5 === 0) {
        passage_with_numbers[i] = "<div>" + i + "</div>" + passage_with_numbers[i];
      }
    }
    this.state = {passage: passage_with_numbers};
  }
  
  render() {
    const { passage } = this.state;
    return (
      <div className={styles.passage}>
         <div className={styles.passageBody} dangerouslySetInnerHTML={{__html: passage.join('<br/>')}}/>
      </div>
    );
  }
}

Passage.propTypes = {
  passage: PropTypes.object.isRequired,
};

export default Passage;