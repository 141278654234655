import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './VerifyUser.module.scss';
import { verifyUser, setTestID } from './../../actions';
import ErrorMessage from './../ErrorMessage';
import Button from '../Button';
import AppTitle from './../AppTitle';
import WaitForIt from '../WaitForIt';

class VerifyUser extends React.Component {

  handleLoginClick = () => {
    this.props.history.replace('/login');
  }

  handleRegisterClick = () => {
    window.location.href = 'https://www.testive.com/express-test';
  }

  componentDidUpdate()  {
    const { isValidUserID, validTestType } = this.props;
    // if we have successfully validated the user send them on
    if (isValidUserID) {
      if(validTestType) {
        this.props.history.replace('/welcome');
      }
      else {
        this.props.history.replace('/choosetest');
      }
    }
  }

  componentDidMount() {
    // if there is a current user logged in then skip this
    // else validate this uuid and send to onboard 
    const { 
      isValidUserID, 
      verifyUser, 
      validTestType,
      setTestID } = this.props;
    const testType = this.props.match.params.test;
    const userUUID = this.props.match.params.id;


    if(testType) {
      setTestID(testType);
    }

    if (isValidUserID) {
      if(validTestType) {
        this.props.history.replace('/welcome');
      }
      else {
        this.props.history.replace('/choosetest');
      }
    }
    else if (userUUID) {
      verifyUser(userUUID);
    }
  }

  renderInvalidUser(error) {
    return (
      <div className={styles.verifyUser}>
        <div className={styles.mainContainer}>
          <AppTitle />
   
          <div className={styles.formContainer}>
            <div className={styles.info}>We could not locate your account</div>
            <ErrorMessage errors={error}></ErrorMessage>
            <div className={styles.helpInfo}>If you need help email</div>
            <a href={'mailto:happy@testive.com'}>happy@testive.com</a>
          </div>
        </div>
      </div>
    );
  }

  renderNoUser() {
    return (
      <div className={styles.verifyUser}>
        <div className={styles.mainContainer}>
          <AppTitle />
          <div className={styles.formContainer}>
            <div className={styles.info}>We could not locate your account</div>
            <div className={styles.buttonContainer}>
              <Button onClick={this.handleLoginClick}>
                Login
              </Button>
              <Button onClick={this.handleRegisterClick}>
                Register
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render(){
    const { isValidatingUserID, isValidUserID, error} = this.props;
    if (isValidatingUserID)
      return (
        <div className={styles.verifyUser}>
          <WaitForIt waitingMessage='authenticating...'></WaitForIt>
        </div>);
    else if (!isValidUserID)
      if (!this.props.match.params.id)
        return this.renderNoUser();
      else
        return this.renderInvalidUser(error);
    else 
      return null;
  }
}

VerifyUser.propTypes = {
  verifyUser: PropTypes.func.isRequired,
  setTestID: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isValidatingUserID: PropTypes.bool.isRequired,
  isValidUserID: PropTypes.bool.isRequired,
  validTestType: PropTypes.string,
  error: PropTypes.string
};

function mapStateToProps(state) {
  return {
    isValidatingUserID: state.user.isAuthenticating,
    isValidUserID: state.user.isAuthenticated,
    validTestType: state.testsession.testID,
    error: state.user.userVerifyError
  };
}

function mapDispatchToProps(dispatch) {
  return {
    verifyUser: (userUUID) => dispatch(verifyUser(userUUID)),
    setTestID: (testID) => dispatch(setTestID(testID))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyUser);