import { 
  // LOGIN, 
  // LOGOUT, 
  USER_VERIFY_REQUEST, USER_VERIFY_SUCCESS, USER_VERIFY_FAILURE,
} from '../constants/actions';

let email = localStorage.getItem('email');
let userUUID = localStorage.getItem('userUUID');
let displayName = localStorage.getItem('displayName');

const initialState = {
  userUUID: userUUID ? userUUID : undefined,
  email: email ? email : '',
  displayName: displayName ? displayName : '',
  isAuthenticating: false,
  isAuthenticated: userUUID ? true : false,
};

const user = (state = initialState, action) => {
  switch(action.type) {
    case USER_VERIFY_REQUEST:
      return Object.assign({}, state, {
        isAuthenticating: true,
        isAuthenticated: false,
        userVerifyError: undefined 
      });

    case USER_VERIFY_SUCCESS:
      localStorage.setItem('userUUID', action.data.user_uuid);
      localStorage.setItem('displayName', action.data.display_name);
      localStorage.setItem('email', action.data.email);

      return Object.assign({}, state, {
        isAuthenticating: false,
        isAuthenticated: true,
        userVerifyError: undefined,
        email: action.data.email,
        userUUID: action.data.user_uuid,
        displayName: action.data.display_name,
      });

    case USER_VERIFY_FAILURE:
      return Object.assign({}, state, {
        isAuthenticating: false,
        isAuthenticated: false,
        userVerifyError: action.error,
      });
   
    
    // case LOGIN:
    //   return Object.assign({}, state, {
    //     email: action.email
    //   });
    
    // case LOGOUT:
    //   localStorage.clear();
   
    //   return Object.assign({}, state, {
    //     email: undefined,
    //     completedTest: false,
    //     displayName: undefined,
    //   });

    default:
      return state;
  }

};

export default user;
