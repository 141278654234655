import React from 'react';
import PropTypes from 'prop-types';

import Percentile from '../Charts/Percentile';
import ScoreCircle from '../Charts/ScoreCircle';
import Button from '../Button';

import styles from './TestSection.module.scss';

class TestSection extends React.Component {

  render() {
    const { section, userSectionData, runTestSection } = this.props;

    var testProgress;
    if (!userSectionData)
    {
      testProgress = (
      <React.Fragment>
        <Button 
          onClick={(e) => runTestSection(section)}
          ctaHighlight={false}
          smallButton={true}
          transparent={true}>
          START
        </Button>
      </React.Fragment>);
    }
    else if (userSectionData.is_completed) {
      testProgress = (
        <React.Fragment>
          <ScoreCircle
            score={userSectionData.score}
            range={800}
            scoreColor={'#fff'}>
          </ScoreCircle>
          <Button 
            onClick={(e) => runTestSection(section)}
            ctaHighlight={false}
            smallButton={true}
            transparent={true}>
            DETAILS
          </Button>
        </React.Fragment>);
    }
    else //assume started 
    {
      const percentComplete = userSectionData.question_count/userSectionData.total_questions;
      testProgress = (
        <React.Fragment>
          <Percentile percent={percentComplete} color={'#fff'}/>
          <div className={styles.chartSpacer}></div>
          <Button 
            onClick={(e) => runTestSection(section)}
            ctaHighlight={false}
            smallButton={true}
            transparent={true}>
            CONTINUE
          </Button>
        </React.Fragment>
      );
    }

    const testSectionStyle = {
      backgroundColor: this.props.color,
      // backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0.25),'+this.props.color+')'
    }

    const sectionDescription = section.description.map((line, index) => 
    { 
      return(<li key={index} className={styles.sectionDescItem}>{line}</li>)
    });

    return (
      <div className={styles.testSection} style={testSectionStyle}>
        <div className={styles.innerBoarder}>
          <div className={styles.sectionInfo}>
            <div className={styles.title}>
              <div className={styles.logo}>
                <img src={section.image} alt={section.displayName}></img> 
              </div>
              <div className={styles.sectionName}>{section.displayName}</div>
            </div>
 
            <div className={styles.sectionDesc}>{sectionDescription}</div> 
          </div>
          
          
          <div className={styles.sectionProgress}>
            {testProgress}
          </div>
        </div>
      </div>
    );
  }
}

TestSection.propTypes = {
  section: PropTypes.object.isRequired,
  userSectionData: PropTypes.object,
  runTestSection: PropTypes.func.isRequired,
  color: PropTypes.string,
};

export default TestSection;