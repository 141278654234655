import React from 'react';
import PropTypes from 'prop-types';
import Percentile from '../Percentile';
// import PercentileChart from '../PercentileChart';

import styles from './PercentileSummary.module.scss';

class PercentileSummary extends React.Component {

  renderSubjects () {
    const { 
      testID,
      math,
      ela,
      science,
      reading,
      english } = this.props;
      
    if (testID === 'SAT') {
      return (
        <React.Fragment>
          <Percentile
            percent={math}
            chartTitle={'Math'}
            color={'#fac58e'} />
          <Percentile
            percent={ela}
            chartTitle={'ELA'}
            color={'#5ba0d0'} />
        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>
          <Percentile
            percent={english}
            chartTitle={'English'}
            color={'#5ba0d0'} />
          <Percentile
            percent={math}
            chartTitle={'Math'}
            color={'#fac58e'} />
           <Percentile
            percent={reading}
            chartTitle={'Reading'} />
          <Percentile
            percent={science}
            chartTitle={'Science'} />
        </React.Fragment>
      );
    }
  }

  render () {
    const { composite } = this.props;
    
    return (
      <div className={styles.percentileSummary}>
        <div className={styles.composite}>
          <Percentile
            percent={composite}
            chartTitle={'Composite'}
            color={'#00a2a7'} />
        </div>
        <div className={styles.sections}>
          {this.renderSubjects()}
        </div>
      </div>
    );
  }
}

PercentileSummary.propTypes = {
  testID: PropTypes.string.isRequired,
  composite: PropTypes.number,
  math: PropTypes.number,
  ela: PropTypes.number,
  science: PropTypes.number,
  reading: PropTypes.number,
  english: PropTypes.number,
};

export default PercentileSummary;