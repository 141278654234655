import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import styles from './TestSectionDirections.module.scss';


class TestSectionDirections extends React.Component {
    
  renderDirection(testTitle) {
    const TestDirections = {
      'science':
        <div>
          <p><span>DIRECTIONS:</span> You will be given 5 minutes to complete the 5 multiple-choice questions that accompany the following science passage. You may refer to the passage as often as necessary as you select your answers.</p>
          <p>You are NOT permitted to use a calculator on this test.</p>
        </div>,
      'math no calculator':
        <div>
          <p>You will be given at least 25 multiple-choice questions on this assessment. For each question, choose the best answer from the choices provided, then confirm your choice to move to the next question.</p>
          <p>Unless otherwise noted, all variables and expressions used represent real numbers and are figures are drawn to scale.</p>
          <p><span>You may not use a calculator on this exam until otherwise notified</span></p>
          <p>Let’s get started!</p>

        </div> , 
      'math': 
        <div>
          <p><span>You are now permitted to use your calculator for the remainder of the exam.</span></p>
        </div> , 
      'reading':
        <div>
          <p><span>DIRECTIONS:</span> You will be given 10 minutes to complete the 10 multiple-choice questions that accompany the following reading passage. Choose the best answer to each question based on what is stated or implied in the passage. You may refer to the passage as often as necessary as you select your answers.</p>
        </div>
    };
    return TestDirections[testTitle.toLowerCase()];
  }

  render() {
    const { 
      testTitle, onDismiss } = this.props;

    return (
      <div className={styles.testSectionDirections}>
        <div className={styles.mainContainer}>        
          <div className={styles.header}>
            <span className={styles.subject}>{testTitle}</span>
            {/* <span className={styles.details}>{totalTime} minutes - {totalQuestions} questions</span> */}
          </div>
                    
          {this.renderDirection(testTitle)}
          <Button 
            ctaHighlight={true} 
            onClick={onDismiss}>
            GOT IT
          </Button>
        </div>
      </div>
    );
  }
}
    
TestSectionDirections.propTypes = {
  testTitle: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired
};
    
export default (TestSectionDirections);