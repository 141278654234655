import { 
  USER_LOGIN_FAILURE,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  LOGOUT} from '../constants/actions';

function userLoginRequest() {
  return {
    type: USER_LOGIN_REQUEST
  };
}

function userLoginSuccess(json) {
  
  return {
    type: USER_LOGIN_SUCCESS,
    receivedAt: Date.now(),
  };
}

function userLoginFailure(error) {
  return {
    type: USER_LOGIN_FAILURE,
    error: error,
    failureAt: Date.now()
  };
}

export function userLogin(email) {
  return function (dispatch) {
    dispatch(userLoginRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let postData = [];
    postData.push(encodeURIComponent('email') + '=' + encodeURIComponent(email));
    
    return fetch('https://app.testive.com/api/a/adaptivetest_login/', {
      method: 'POST',
      body: postData.join('&'),
      headers: header
    }).then(
      response => response.json(),
      error => dispatch(userLoginFailure(error.message))
    )
      .then(
        function(json) {
          if (json.status === 'ok') {
            dispatch(userLoginSuccess(json));
          } else if(json.status === 'error'){
            dispatch(userLoginFailure(json.msg));
          }
        }
      );
  };
}

export function userLogout() {
  return {
    type: LOGOUT
  };
}
