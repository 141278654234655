import React from 'react';
import PropTypes from 'prop-types';

import KatexText from './../KatexText';
import Answer from './../Answer';
import Button from '../Button';
import DetectDesktopOrMobile from '../DetectDesktopOrMobile';

import styles from './Question.module.scss';
class Question extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      answerSelected: -1
    };
  } 
  
  answerClicked = (answerID) => {
    this.setState({
      answerSelected: answerID
    });
  }

  submitAnswer() {
    this.props.chooseAnswer(this.state.answerSelected);
    this.setState({
      answerSelected: -1
    });
  }
  
  render() {
    const { questionNumber, questionBody, answerOptions } = this.props;
    const AnswerResponsive = DetectDesktopOrMobile(Answer);
    
    const answerChoices = Object.values(answerOptions).map((answerOption, index) => 
    {
      var answerID = index+1;
      return(
        <AnswerResponsive
          key={index*questionNumber}
          answerID={answerID}
          selected={answerID === this.state.answerSelected}
          answerText={answerOption}
          answerClicked={this.answerClicked}>
        </AnswerResponsive>);
    }); 

    return (
      <div className={styles.question}>
        <div className={styles.questionBody}>
          <div className={styles.questionNumber}>{questionNumber}.</div>
          <KatexText text={questionBody} />
        </div>
        <div className={styles.answerSet}>
          {answerChoices}
        </div>
        <div className={styles.answerButton}>
          <Button 
            onClick={this.submitAnswer.bind(this)}
          >
            Submit answer
          </Button>
        </div>
      </div>
    );
  }
}

Question.propTypes = {
  questionNumber: PropTypes.number.isRequired,
  questionBody: PropTypes.node.isRequired,
  answerOptions: PropTypes.object.isRequired,
  chooseAnswer: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired
};

Question.defaultProps = {
  darkMode: true
};

export default Question;