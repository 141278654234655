import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './AppTitle.module.scss';
import Images from './../../assets/Images';

class AppTitle extends Component {

  render() {
    return (
      <div className={styles.appTitle}>
        <img src={Images.appTitleLogo} alt='Testive Score Predictor'></img>
      </div>);
  };
}

AppTitle.propTypes = {
  testType: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    testType: state.testsession.testID
  };
}

export default connect(mapStateToProps)(AppTitle);
