import { EventTypes } from 'redux-segment';

import { 
  USER_VERIFY_REQUEST, 
  USER_VERIFY_SUCCESS, 
  USER_VERIFY_FAILURE } from '../constants/actions';

const server = 'https://app.testive.com';
//const server = 'http://127.0.0.1:8000';

function userVerifyRequest() {
  return {
    type: USER_VERIFY_REQUEST
  };
}

function userVerifySuccess(json) {
  let analytics = {
    eventType: EventTypes.identify,
    eventPayload: {
      userId: json.data.email,
    }
  };

  return {
    type: USER_VERIFY_SUCCESS,
    receivedAt: Date.now(),
    data: json.data,
    meta: {
      analytics
    }
  };
}

function userVerifyFailure(error) {
  return {
    type: USER_VERIFY_FAILURE,
    error: error,
    failureAt: Date.now()
  };
}

export function verifyUser(userUUID) {
  return function (dispatch) {
    dispatch(userVerifyRequest());

    let header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let postData = [];
    postData.push(encodeURIComponent('user_uuid') + '=' + encodeURIComponent(userUUID));
    
    return fetch(server+'/api/a/adaptivetest_confirm_account/', {
      method: 'POST',
      body: postData.join('&'),
      headers: header
    }).then(
      response => response.json(),
      error => dispatch(userVerifyFailure(error.message))
    )
      .then(
        function(json) {
          if (json.status === 'ok') {
            dispatch(userVerifySuccess(json));
          } else if(json.status === 'error'){
            dispatch(userVerifyFailure(json.msg));
          }
        }
      );
  };
}
