import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './WaitForIt.module.scss';

class WaitForIt extends Component {

  render() {
    const { waitingMessage } = this.props;

    return (
        <div className={styles.waitForIt}>
          <div className={styles.waitingMessage}>{waitingMessage}</div>
          <div className={styles.spinner}>
              <div className={styles.bounce1}></div>
              <div className={styles.bounce2}></div>
              <div className={styles.bounce3}></div>
          </div>
        </div>
    );
  }
}

WaitForIt.propTypes = {
  waitingMessage: PropTypes.string,
}

export default WaitForIt; 

