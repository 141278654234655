import React from 'react';
import PropTypes from 'prop-types';

import 'katex/dist/katex.min.css';
import katex from 'katex';
import styles from './KatexText.module.scss';

class KatexText extends React.Component {

  parseKatex(string) {
    let r = /\[math\].*?\[\/math\]/gm;

    string = string.replace(r, function(match) {

      match = match.replace('[math]','');
      match = match.replace('[/math]','');

      try{
        return katex.renderToString(match);
      }
      catch(e){
        return match
      }
    });

    return string;
  }

  render() {
    const { text } = this.props;
    const requiresKatex = text.toString().includes('[math]') || text.toString().includes('<img');
     
    if (requiresKatex) {
      return <span className={styles.katexText} dangerouslySetInnerHTML={{__html:this.parseKatex(text)}}/>;     
    }
    else {
      return <span dangerouslySetInnerHTML={{__html:text}} />;
    }

  }
}

KatexText.propTypes = {
  text: PropTypes.node.isRequired,
};

export default KatexText;