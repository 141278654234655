import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '../Button';
import AppTitle from '../AppTitle';

import styles from './WelcomeScreen.module.scss';

class WelcomeScreen extends React.Component {

  startTest = () => {
    this.props.history.push('/sections/');
  }

  render() {
    const { testType } = this.props;
    return (
      <div className={styles.welcomeScreen}>
        <div className={styles.mainContainer}>
          <AppTitle />
          <div className={styles.instructions}>
            <div>
              Get started on the path to {testType} success with this smart test. After completing the rapid assessment, you&#39;ll receive a prediction of your {testType} score and an analysis of your strengths and weaknesses to help you efficiently prepare for the official test.
            </div> 
            <div className={styles.list}>
              <div className={styles.listTitle}>This predictive test is comprised of three computer-based adaptive sections:</div>
              <ul>
                <li>Math</li>
                <li>Reading</li>
                <li>English / Grammar / Language</li>
              </ul>
            </div>
            <div className={styles.list}>
              <div className={styles.listTitle}>Before you start, make sure you have…</div>
              <ul>
                <li>90 uninterrupted minutes</li>
                <li>A pencil and scratch paper</li>
                <li>A calculator <span>(only for use on the Math with Calculator section)</span></li>
                <li>A positive attitude...you can do it!</li>
              </ul>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Button 
              onClick={(e) => this.startTest()}
              ctaHighlight={false}>
                CONTINUE
            </Button>
          </div>
        
        </div>
      </div>
    );
  }
}

WelcomeScreen.propTypes = {
  testType: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    testType: state.testsession.testID,
  };
}

export default connect(mapStateToProps)(WelcomeScreen);